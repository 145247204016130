<template>
  <common style="display: contents">
    <main-header />
    <div class="error">
      <div class="container">
        <div class="error__wrap">
          <div class="error__code">{{ error?.statusCode }}</div>
          <div
            v-if="error?.statusCode?.toString?.() === '404'"
            class="error__message"
          >
            Page Not Found
          </div>
          <div v-else class="error__message error__message--other">
            {{ error?.statusMessage }}
            <code>{{ error }}</code>
          </div>
          <div
            v-if="error?.statusCode?.toString?.() === '404'"
            class="error__text"
          >
            We're sorry, the page you requested could not be found. <br />Please
            go back to the homepage.
          </div>
          <hb-btn theme="secondary" rounded size="lg" @click="handleError">
            Homepage
          </hb-btn>
        </div>
      </div>
    </div>
  </common>
</template>
<script lang="ts">
import { clearError } from '#app'
import { useError } from '#imports'
import MainHeader from '~/components/layout/MainHeader.vue'
import Common from '~/layouts/common.vue'
import HbBtn from '~/components/base/utils/HbBtn.vue'

export default {
  components: {
    Common,
    MainHeader,
    HbBtn,
  },
  setup() {
    const handleError = () => clearError({ redirect: '/' })
    const error = useError()

    return {
      handleError,
      error,
    }
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/error.scss';
</style>
